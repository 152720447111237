
.ui.vertical.buttons {
  width: 100%;
}
.ui.vertical.buttons > .mobile-button:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.ui.vertical.buttons > .mobile-button {
  width: 100%;
  margin-left: 0;
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
}
.ui.vertical.buttons > .mobile-button:not(:first-child):not(:last-child) {
  border-bottom: 0;
  border-radius: 0;
}
.ui.vertical.buttons > .mobile-button:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
