
.row {
  margin-bottom: 1em;
}
th {
  white-space: nowrap;
}
.help-content {
  color: #48576a;
  font-size: 0.9rem;
}
.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
  /*-webkit-overflow-scrolling: touch;*/
}
.scroll-x::-webkit-scrollbar {
  height: 5px;
}
.scroll-x::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #eee;
}
.scroll-x::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}
