
.ui.form .fields {
  display: block;
  margin-bottom: 0;
}
.fields > .field {
  display: inline-block;
}
.field ::v-deep .el-radio {
  padding-left: 30px;
}
.field ::v-deep .el-radio__input {
  margin-left: -28px;
}
