
.el-cascader-menu::-webkit-scrollbar {
  width: 5px;
}
.el-cascader-menu::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #eee;
}
.el-cascader-menu::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}
