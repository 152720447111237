
::v-deep .el-dialog {
  .el-dialog__header {
    padding: 32px 32px 16px;
  }

  .el-dialog__headerbtn {
    top: 32px;
    right: 32px;
    .el-dialog__close {
      color: black;
    }
  }

  .el-dialog__body {
    padding: 0 32px;
  }

  .el-dialog__footer {
    padding: 32px;
  }
}
