/* ==== custom classes for Semantic UI ==== */
.ui.border-radius-none {
  border-radius: unset !important;
}

body {
  background-color: #ffffff;
  display: flex; // 管理画面で左メニューと右設定項目をbodyに対してスクロールさせるため.そのDOM至るすべての子要素にflexを付ける必要がある.
  flex-flow: column; // 管理画面以外のフッターの縦並びのため
  letter-spacing: 1px;

  &:has(.ui.raised.segment) {
    background-color: #eef0f0;
  }

  &>main {
    flex: 1 0 auto; // 管理画面以外のフッターを下部に配置するため
  }

  footer {
    margin: 1rem 0 0 !important;
    white-space: nowrap;

    &.ui.segment {
      // counter styling defined later (which is a historical one)
      min-height: auto;
    }

    a {
      color: inherit;
      cursor: pointer;
      font-size: 12px;
      letter-spacing: 0.5px;

      &:hover {
        color: inherit;
        background: inherit;
        text-decoration: underline;
      }
    }

    // normalize Semantic UI definitions which are overwritten
    // later in this file. This is specific only for FB.
    &.grid {
      height: auto;
    }
  }

  .grid {
    height: 100%;
  }

  .ui.checkbox label {
    cursor: pointer;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }

  .bounce-leave-active {
    animation: bounce-out .5s;
  }

  .ui.grid {
    .message-box.column {
      max-width: 450px;
    }
  }

  .ui.segment {
    &.no-top-padded {
      padding-top: 0 !important;
    }
  }

  .ui.icon.button.white {
    background-color: white;
    padding: 00.78571429em 0.3em;
    margin: 0;
    display: inline-block;
  }

  .ui.form {
    .el-input--prefix .el-input__inner {
      padding-left: 30px;
    }

    .el-input--suffix .el-input__inner {
      padding-right: 30px;
    }

    .el-date-editor .el-range-input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      outline: 0;
      padding: 0;
      width: 39%;
      color: #606266;
    }

    .el-date-editor .el-range-input,
    .el-date-editor .el-range-separator {
      height: 100%;
      margin: 0;
      text-align: center;
      font-size: 14px;
      display: inline-block;
    }

    .el-checkbox__input {
      position: absolute;
      margin-top: 4px;
    }

    .el-checkbox__label {
      margin-left: 1.5em;
    }

    // IEだとel-cascaderのmultiselectがなぜか表示されない対処
    .el-cascader__tags .el-tag {
      display: inline-block;
    }

    .kviewer-lookup {
      .el-dialog.el-dialog--large.kviewer-lookup-modal {
        overflow: auto;
      }

      .kviewer-lookup-input {
        input[type="text"].el-input__inner {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .kviewer-lookup-button {
          color: #fff;
          background-color: #409EFF;
          border-color: #409EFF;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    fieldset.fieldset {
      border: none;
      padding: 0;
      margin: 0 0 2.5rem;
    }
  }

  .el-form-item__label {
    font-weight: bold;
  }

  .el-form-item__error {
    position: static;
  }

  .text-right {
    text-align: right !important;
  }

  .icon.margin-adjusted {
    /* overwrite SUI's default to remove the unconscious margin for icon */
    margin: 0 .2rem 0 !important;
  }

  .ui.icon.circular.button {
    &.appending {
      margin-top: 0.4rem;
      margin-left: 0.5rem;
    }
  }

  // deprecated: <el-dialog>をデザインシステムに合わせるための一時的な対応。
  // TODO: <fb-el-dialog>に内包する。
  button.el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
  }
}
