
.kapp-auth-userpage-logout {
  max-width: 640px;

  /* footerが14pxのmaring-topをもつため, footer表示時はmargin合計が40pxとなるようにmargin-bottomを設定*/
  margin: 40px auto calc(40px - 1rem);

  padding: 16px;
  border-radius: 4px;
  background-color: #fff;

  text-align: center;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #999;

  &.isHideCopyright {
    margin-bottom: 40px;
  }

  p {
    margin: 16px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: #0079d2;
    cursor: pointer;

    &.to-user-page {
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
