
.navigation {
  position: fixed;
  top: 8px;
  right: 8px;

  margin: 0;
  padding: 0;

  background-color: #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));

  // loading-mask の上に重ねる
  z-index: 3000;

  width: 32px;
  border-radius: 16px;

  // アイコンの配置
  .navigation-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .navigation-button {
      position: absolute;
      margin: 0;
      padding: 0;
      cursor: pointer;

      // text descender により SVG がボックスの下へずれるのを回避
      line-height: 0;

      top: 7px;
      right: 7px;
    }
  }

  &:not(.expanded) {
    height: 32px;

    &:hover {
      background-color: #f2f2f2;
    }
  }

  // テキスト表示時
  &.expanded {
    width: 344px;
    border-radius: 4px;

    .navigation-wrapper {
      .navigation-button {
        top: 16px;
        right: 16px;
      }
    }

    // テキストの配置
    .navigation-content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 21px;

      &__main {
        &__title {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }
}
