
.ui.grid > .row > .column {
  margin-bottom: 0.5em;
}
.el-form-item__error {
  position: static;
}
.column.field.el-form-item.subtable-field {
  width: 100%;
}
.help-content {
  color: #48576a;
  font-size: 0.9rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
