@tailwind components;
@tailwind utilities;

@layer components {
  .flex-scrollable {
    @apply grow shrink-0 basis-0 overflow-x-hidden overflow-y-auto;
  }
}

/* Sematic UI Iconの .outlineとtailwindのスタイルがバッティングして不要な枠線が表示されるため上書き */
i {
  outline: none !important;
}
