
.mobile-dropdown {
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}
.ui.basic.label {
  color: #606266;
  font-weight: normal;
}
.mobile-popup-content {
  width: 100%;
  height: 60%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
